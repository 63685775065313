import LayoutAuth from "components/layouts/LayoutAuth";
import Meta from "solar/components/Meta";
import Link from "next/link";
import Title from "components/shared/stays/Title";
import Text from "components/shared/stays/Text";
import PageCard from "components/auth/PageCard";
import PageFooter from "components/auth/PageFooter";
import LoginForm from "components/auth/LoginForm";
import FormSubtext from "components/auth/FormSubtext";

export default function Login() {
  return (
    <>
      <Meta title="Log in" path="/login" />
      <PageCard>
        <Title size={4}>Log in</Title>
        <LoginForm />
        <FormSubtext>
          <Text size={2}>
            <Link prefetch={false} href="/forgot-password">
              Forgot your password?
            </Link>
          </Text>
        </FormSubtext>
        <PageFooter>
          <Text>
            {"Don't have an account? "}
            <Link prefetch={false} href="/signup">
              Sign up
            </Link>
          </Text>
        </PageFooter>
      </PageCard>
    </>
  );
}

Login.Layout = LayoutAuth;
