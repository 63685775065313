import PropTypes from "prop-types";
import Navbar from "components/shared/stays/Navbar";
import Footer from "components/shared/stays/Footer";
import Container from "solar/components/Container";
import ChatButton from "components/shared/stays/ChatButton";
import styles from "components/layouts/LayoutAuth.module.scss";

export default function LayoutAuth({ children }) {
  return (
    <div className={styles.component}>
      <div className={styles.grid}>
        <Navbar
          smallPrimary="back"
          smallSecondary="menu"
          bigSecondary="logo"
          bigTertiary="menu"
          bigWide
          sticky
        />
        <div className={styles.alignment}>
          <Container>{children}</Container>
        </div>
      </div>
      <Footer />
      <ChatButton type="icon-overlay" overlay />
    </div>
  );
}

LayoutAuth.propTypes = {
  children: PropTypes.node.isRequired,
};
