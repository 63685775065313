import PropTypes from "prop-types";
import styles from "components/auth/PageCard.module.scss";

export default function PageCard(props) {
  const { children } = props;

  return <div className={styles.component}>{children}</div>;
}

PageCard.propTypes = {
  children: PropTypes.node.isRequired,
};
