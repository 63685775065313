import PropTypes from "prop-types";
import styles from "components/auth/PageFooter.module.scss";

export default function PageFooter(props) {
  const { children } = props;

  return <div className={styles.component}>{children}</div>;
}

PageFooter.propTypes = {
  children: PropTypes.node.isRequired,
};
